import React from "react";
import { useDataGrid, EditButton, ShowButton, DeleteButton, List, MarkdownField, DateField } from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ButtonGroup from "@mui/material/ButtonGroup";
import { NavLink } from "react-router-dom";
import { DATE_TIME_FORMAT } from "@/Environment";

export default function ActionsMenu({ row }: { row: any }) {
  const translate = useTranslate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <Button
        id="actions-menu-button"
        aria-controls={open ? "actions-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="small"
      >
        <ArrowDropDownIcon fontSize="small" />
      </Button>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "actions-menu-button",
        }}
      >
        <MenuItem onClick={handleClose} component={NavLink} to={`/customers/${row.id}/products`}>
          {translate("actions-menu.products", "Products")}
        </MenuItem>
        <MenuItem onClick={handleClose} component={NavLink} to={`/customers/${row.id}/users`}>
          {translate("actions-menu.users", "Users")}
        </MenuItem>
      </Menu>
    </div>
  );
}

export const CustomerList: React.FC<IResourceComponentsProps> = (props) => {
  const translate = useTranslate();

  // const { resource, identifier } = useResource();

  const { dataGridProps } = useDataGrid({
    queryOptions: {
      refetchOnMount: true,
      cacheTime: 0,
      staleTime: 0,
      retry: false,
    },
  });

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "actions",
        headerName: translate("table.actions"),
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <ButtonGroup className="ListActions" color="info" variant="outlined" size="small">
              <EditButton hideText recordItemId={row.id} />
              <ShowButton hideText recordItemId={row.id} />
              <DeleteButton hideText recordItemId={row.id} />
              <ActionsMenu row={row} />
            </ButtonGroup>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 170,
        maxWidth: 170,
      },
      {
        field: "id",
        flex: 1,
        headerName: translate("customers.fields.id"),
        type: "number",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        field: "code",
        headerName: translate("customers.fields.code"),
        minWidth: 200,
      },
      {
        field: "displayName",
        flex: 1,
        headerName: translate("customers.fields.displayName"),
        minWidth: 200,
      },
      {
        field: "description",
        flex: 1,
        headerName: translate("customers.fields.description"),
        minWidth: 250,
        sortable: false,
        renderCell: function render({ value }) {
          return <MarkdownField value={(value ?? "").slice(0, 80) + "..."} />;
        },
      },
      {
        field: "contactName",
        flex: 1,
        headerName: translate("customers.fields.contactName"),
        minWidth: 200,
      },
      {
        field: "contactEmail",
        flex: 1,
        headerName: translate("customers.fields.contactEmail"),
        minWidth: 200,
      },
      {
        field: "updatedOn",
        flex: 1,
        headerName: translate("customers.fields.updatedOn"),
        maxWidth: 170,
        renderCell: function render({ value }) {
          return <DateField className="DateTimeFullCell" value={value} format={DATE_TIME_FORMAT} />;
        },
      },
    ],
    [translate]
  );

  return (
    <List>
      <DataGrid {...dataGridProps} columns={columns} autoHeight />
    </List>
  );
};
