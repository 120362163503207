import React from "react";

import "./EnvironmentIndicator.css";

import { ENVIRONMENT } from "@/Environment";

export interface EnvironmentIndicatorProps {
  fill?: boolean;
}

export const EnvironmentIndicator: React.FC<EnvironmentIndicatorProps> = ({ fill }) => {
  if (ENVIRONMENT === "prod") {
    return null;
  }
  return (
    <div className="EnvironmentIndicator" data-environment={ENVIRONMENT}>
      {ENVIRONMENT}
    </div>
  );
};
