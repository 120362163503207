import React from "react";

export interface AppIconProps {
  size?: number;
  fill?: string;
}

export const AppIcon: React.FC<AppIconProps> = ({ size = 24, fill = "currentColor" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={`${size}`} height={`${size}`} viewBox="0 0 16 16" fill="none">
      <g fill={fill}>
        <path d="M5 0a2 2 0 0 0-2 2v5a5.002 5.002 0 0 0 3.216 4.672c.444.17.784.57.784 1.045V13a1 1 0 0 1-1 1H4a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2h-2a1 1 0 0 1-1-1v-.283c0-.475.34-.875.784-1.045A5.002 5.002 0 0 0 13 7V2a2 2 0 0 0-2-2zM0 3a1 1 0 0 1 2 0v4a1 1 0 0 1-2 0zM15 2a1 1 0 0 0-1 1v4a1 1 0 1 0 2 0V3a1 1 0 0 0-1-1z" />
      </g>
    </svg>
  );
};
