import { useShow, IResourceComponentsProps, useTranslate, useResource, useOne } from "@refinedev/core";
import {
  Show,
  NumberField,
  TextFieldComponent as TextField,
  RefreshButton,
  EditButton,
  DeleteButton,
} from "@refinedev/mui";
import { Typography, Stack, Box } from "@mui/material";
import { useMatch } from "react-router-dom";
import { useMemo } from "react";
import { CustomerProductsBreadcrumbs } from "@/components/breadcrumbs";
import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";

export const CustomerProductPermissionTemplateShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();

  const { resource } = useResource();
  const match = useMatch(`${resource?.show}`);
  const customer_id = match?.params.customer_id ?? "";
  const product_id = match?.params.product_id ?? "";
  const meta = useMemo(
    () => ({
      customer_id,
      product_id,
    }),
    [customer_id, product_id]
  );

  const { data: customer, isLoading: customerIsLoading } = useOne({
    resource: "customers",
    id: customer_id,
  });

  const { data: product, isLoading: productIsLoading } = useOne({
    resource: "products",
    id: product_id,
  });

  const { queryResult } = useShow({
    meta,
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const isPending = isLoading || customerIsLoading || productIsLoading;

  return (
    <Show
      isLoading={isPending}
      breadcrumb={
        <CustomerProductsBreadcrumbs
          customer={customer?.data}
          product={product?.data}
          lastPaths={[
            {
              id: "customer-product-permission-templates",
              path: `/customers/${customer?.data.id}/products/${product?.data.id}/permission-templates`,
              title: translate("permission-templates.permission-templates", "Product Permission Templates"),
            },
          ]}
        />
      }
      headerButtons={({ refreshButtonProps, editButtonProps, deleteButtonProps }) => (
        <Box display="flex" gap="16px">
          <EditButton {...editButtonProps} meta={meta} />
          <DeleteButton {...deleteButtonProps} meta={meta} />
          <RefreshButton {...refreshButtonProps} meta={meta} />
        </Box>
      )}
    >
      {isPending ? (
        <ContentLoadingIndicator fill />
      ) : (
        <Stack gap={1}>
          <Typography variant="body1" fontWeight="bold">
            {translate("customer-product-permissions.fields.id")}
          </Typography>
          <NumberField value={record?.id ?? ""} />

          <Typography variant="body1" fontWeight="bold">
            {translate("customer-product-permissions.fields.expressionTemplate")}
          </Typography>
          <TextField value={record?.expressionTemplate} />

          <Typography variant="body1" fontWeight="bold">
            {translate("customer-product-permissions.fields.expectedKeys")}
          </Typography>
          <TextField value={record?.expectedKeys} />

          <Typography variant="body1" fontWeight="bold">
            {translate("customer-product-permissions.fields.description")}
          </Typography>
          <TextField value={record?.description} />
        </Stack>
      )}
    </Show>
  );
};
