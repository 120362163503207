import { Edit, RefreshButton } from "@refinedev/mui";
import { Box, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useOne, useResource, useTranslate } from "@refinedev/core";
import { useMatch } from "react-router-dom";
import { useMemo } from "react";
import { CustomerBreadcrumbs } from "@/components/breadcrumbs";
import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";

export const CustomerProductEdit: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { resource } = useResource();
  const match = useMatch(`${resource?.edit}`);
  const customer_id = match?.params.customer_id ?? "";
  const product_id = match?.params.product_id ?? "";
  const meta = useMemo(
    () => ({
      customer_id,
      product_id,
    }),
    [customer_id, product_id]
  );

  const { data: customer, isLoading: customerIsLoading } = useOne({
    resource: "customers",
    id: customer_id,
  });

  const {
    saveButtonProps,
    refineCore: { queryResult },
    register,
    formState: { errors },
  } = useForm();

  const isPending = queryResult?.isLoading || customerIsLoading;

  return (
    <Edit
      isLoading={isPending}
      breadcrumb={
        <CustomerBreadcrumbs
          customer={customer?.data}
          lastPaths={[
            {
              id: "products-list",
              path: `/customers/${customer_id}/products`,
              title: translate("products.products", "Products"),
            },
          ]}
        />
      }
      saveButtonProps={saveButtonProps}
      headerButtons={({ refreshButtonProps }) => (
        <Box display="flex" flexDirection="row" alignItems="center">
          <RefreshButton {...refreshButtonProps} meta={meta} />
        </Box>
      )}
    >
      {isPending ? (
        <ContentLoadingIndicator fill />
      ) : (
        <Box component="form" sx={{ display: "flex", flexDirection: "column" }} autoComplete="off">
          <TextField
            {...register("id", {
              required: "This field is required",
              valueAsNumber: true,
            })}
            error={!!(errors as any)?.id}
            helperText={(errors as any)?.id?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="number"
            label={translate("products.fields.id")}
            name="id"
            disabled
          />
          <TextField
            {...register("code", {
              required: "This field is required",
            })}
            error={!!(errors as any)?.code}
            helperText={(errors as any)?.code?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label={translate("products.fields.code")}
            name="code"
          />
          <TextField
            {...register("displayName", {
              required: "This field is required",
            })}
            error={!!(errors as any)?.displayName}
            helperText={(errors as any)?.displayName?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label={translate("products.fields.displayName")}
            name="displayName"
          />
          <TextField
            {...register("description", {
              required: "This field is required",
            })}
            error={!!(errors as any)?.description}
            helperText={(errors as any)?.description?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label={translate("products.fields.description")}
            name="description"
          />
        </Box>
      )}
    </Edit>
  );
};
