import { Typography } from "@mui/material";

import "./NonIdealState.css";

export function NonIdealState({ IconComponent, iconFontSize, title, description, action }: any) {
  return (
    <div className="NonIdealState">
      {IconComponent ? <IconComponent fontSize="large" /> : null}
      <Typography variant="h6">{title}</Typography>
      {description ? <Typography variant="subtitle1">{description}</Typography> : null}
      {action}
    </div>
  );
}
