import { Create, useAutocomplete } from "@refinedev/mui";
import { Autocomplete, Box, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useOne, useResource, useTranslate } from "@refinedev/core";
import { Controller } from "react-hook-form";
import { CustomerBreadcrumbs } from "@/components/breadcrumbs";
import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";

export const CustomerProductCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const {
    saveButtonProps,
    refineCore: { formLoading },
    control,
    formState: { errors },
  } = useForm();

  const { id } = useResource();

  const { data: customer, isLoading: customerIsLoading } = useOne({
    resource: "customers",
    id,
  });

  const { autocompleteProps: serviceAutocompleteProps } = useAutocomplete({
    resource: "products",
  });

  const isPending = formLoading || customerIsLoading;

  return (
    <Create
      breadcrumb={
        <CustomerBreadcrumbs
          customer={customer?.data}
          lastPaths={[
            {
              id: "products-list",
              path: `/customers/${id}/products`,
              title: translate("products.products", "Products"),
            },
          ]}
        />
      }
      isLoading={isPending}
      saveButtonProps={saveButtonProps}
    >
      {isPending ? (
        <ContentLoadingIndicator fill />
      ) : (
        <Box component="form" sx={{ display: "flex", flexDirection: "column" }} autoComplete="off">
          <Controller
            control={control}
            name="product"
            rules={{ required: "This field is required" }}
            defaultValue={null}
            render={({ field }) => (
              <Autocomplete
                {...serviceAutocompleteProps}
                {...field}
                onChange={(_, value) => {
                  field.onChange(value);
                }}
                getOptionLabel={(item) => {
                  return item.displayName;
                }}
                isOptionEqualToValue={(option, value) => {
                  const isEqual = option.id === value.id;
                  return isEqual;
                }}
                renderInput={(params) => (
                  <TextField
                    // sx={{ width: 250 }}
                    {...params}
                    label={translate("customer-products.fields.associatedProduct")}
                    margin="normal"
                    fullWidth
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    error={!!(errors as any)?.title}
                    helperText={(errors as any)?.title?.message}
                    required
                  />
                )}
              />
            )}
          />
        </Box>
      )}
    </Create>
  );
};
