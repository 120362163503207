import React from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";

// project

import "@/i18n";

import { AUTH0_CLIENT_ID, AUTH0_DOMAIN, CSPM_API_BASE_URL, ENVIRONMENT, PROJECT_VERSION } from "@/Environment";
import { Tracing } from "@/App.tracing";
import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";
import "./index.css";
import App from "@/App";

function renderApplication() {
  const domNode = document.getElementById("root") as HTMLElement;
  const root = createRoot(domNode);
  root.render(
    <React.Suspense fallback={<ContentLoadingIndicator fill />}>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        authorizationParams={{ redirect_uri: window.location.origin }}
      >
        <App />
      </Auth0Provider>
    </React.Suspense>
  );
}

function entryPoint() {
  try {
    console.debug("Starting application", { ENVIRONMENT, VERSION: PROJECT_VERSION, API: CSPM_API_BASE_URL });
    Tracing.init();
    renderApplication();
  } catch (error) {
    console.error("Application bootstrap error", error);
    Tracing.capture(error);
  }
}

entryPoint();
