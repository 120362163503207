import React from "react";
import {
  useDataGrid,
  DeleteButton,
  List,
  MarkdownField,
  DateField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { ButtonGroup } from "@mui/material";
import { EditOutlined, VisibilityOutlined } from "@mui/icons-material";
import { ListActionButton } from "@/components/buttons/ListActionButton";
import { DATE_TIME_FORMAT } from "@/Environment";

export const RoleList: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { dataGridProps } = useDataGrid();
  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "actions",
        headerName: translate("table.actions"),
        sortable: false,
        renderCell: function render({ row }) {
          return (
            <ButtonGroup
              className="ListActions"
              color="info"
              variant="outlined"
              size="small"
            >
              <ListActionButton
                recordItemId={row.id}
                customActionUrl={`/customers/${row.customer}/products/${row.product}/roles/${row.id}/edit`}
                IconComponent={EditOutlined}
                hideText
                meta={{
                  product_id: row.product,
                  customer_id: row.customer,
                }}
              />
              <ListActionButton
                recordItemId={row.id}
                customActionUrl={`/customers/${row.customer}/products/${row.product}/roles/${row.id}/show`}
                IconComponent={VisibilityOutlined}
                hideText
                meta={{
                  product_id: row.product,
                  customer_id: row.customer,
                }}
              />
              <DeleteButton hideText recordItemId={row.id} />
            </ButtonGroup>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 130,
        maxWidth: 170,
      },
      {
        field: "id",
        headerName: translate("roles.fields.id"),
        type: "number",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        field: "name",
        flex: 1,
        headerName: translate("roles.fields.name"),
        minWidth: 220,
      },
      {
        field: "description",
        flex: 1,
        headerName: translate("roles.fields.description"),
        minWidth: 480,
        sortable: false,
        renderCell: function render({ value }) {
          return <MarkdownField value={(value ?? "").slice(0, 80) + "..."} />;
        },
      },
      {
        field: "updatedOn",
        flex: 1,
        headerName: translate("roles.fields.updatedOn"),
        maxWidth: 170,
        renderCell: function render({ value }) {
          return <DateField className="DateTimeFullCell" value={value} format={DATE_TIME_FORMAT} />;
        },
      },
    ],
    [translate]
  );

  return (
    <List>
      <DataGrid {...dataGridProps} columns={columns} autoHeight />
    </List>
  );
};
