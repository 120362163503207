import { useMemo } from "react";
import {
  useShow,
  IResourceComponentsProps,
  useTranslate,
  useResource,
} from "@refinedev/core";
import {
  Show,
  DateField,
  CreateButton,
  EditButton,
  RefreshButton,
} from "@refinedev/mui";
import { Typography, Stack, Box } from "@mui/material";
import { SearchOffOutlined } from "@mui/icons-material";

import { NonIdealState } from "@/components/information/NonIdealState";
import { DATE_TIME_FORMAT } from "@/Environment";
import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";
import { AdvancedEditor } from "@/components/editors/AdvancedEditor";

export const ProductPermissionModelShow: React.FC<
  IResourceComponentsProps
> = () => {
  const { id } = useResource();
  const translate = useTranslate();
  const { queryResult } = useShow({
    queryOptions: {
      retry: false,
      retryOnMount: false,
    },
  });
  const { data, isLoading } = queryResult;
  const meta = useMemo(
    () => ({
      id,
      product_id: id,
    }),
    [id]
  );

  const record = data?.data;
  const dataExists = (data as any)?.exists;

  return (
    <Show
      isLoading={isLoading}
      canEdit={dataExists}
      headerButtons={({ refreshButtonProps, editButtonProps }) => (
        <Box display="flex" gap="16px">
          {dataExists ? <EditButton {...editButtonProps} meta={meta} /> : null}
          <RefreshButton {...refreshButtonProps} meta={meta} />
        </Box>
      )}
    >
      {isLoading ? (
        <ContentLoadingIndicator fill />
      ) : dataExists ? (
        <Stack gap={1}>
          <Typography variant="body1" fontWeight="bold">
            {translate("product-permission-model.fields.permissionModel")}
          </Typography>
          <AdvancedEditor readOnly value={record?.permissionModel ?? ""} />
          <Typography variant="body1" fontWeight="bold">
            {translate("product-permission-model.fields.createdOn")}
          </Typography>
          <DateField value={record?.createdOn} format={DATE_TIME_FORMAT} />
          <Typography variant="body1" fontWeight="bold">
            {translate("product-permission-model.fields.updatedOn")}
          </Typography>
          <DateField value={record?.updatedOn} format={DATE_TIME_FORMAT} />
        </Stack>
      ) : (
        <>
          <NonIdealState
            title={translate(
              "product-permission-model.model-not-found",
              "Model not defined"
            )}
            description={translate(
              "product-permission-model.c2a.create",
              "No model exists for this product, click the button below to define one!"
            )}
            IconComponent={SearchOffOutlined}
            iconFontSize={64}
            action={
              <CreateButton
                meta={{
                  product_id: id,
                }}
              />
            }
          />
        </>
      )}
    </Show>
  );
};
