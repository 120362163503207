import { useContext, useEffect, useState } from "react";
import Editor from "@monaco-editor/react";
import { ColorModeContext } from "@/contexts/color-mode";

export interface AdvancedEditorProps {
  name?: string;
  value?: string | null;
  defaultLanguage?: string;
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: any;
}

import "./AdvancedEditor.css";

export const AdvancedEditor: React.FC<AdvancedEditorProps> = ({
  value,
  onChange,
  defaultLanguage = "ini",
  readOnly = false,
  disabled = false,
}) => {
  const { mode } = useContext(ColorModeContext);
  const [code, setCode] = useState(value ?? "");
  useEffect(() => {
    setCode((value as any) ?? "");
  }, [value]);
  return (
    <div className="AdvancedEditor">
      <Editor
        theme={mode === "dark" ? "vs-dark" : "vs-light"}
        height="40vh"
        defaultLanguage={defaultLanguage}
        value={code}
        onChange={(value, e) => {
          setCode(value ?? "");
          if (onChange) {
            onChange(value);
          }
        }}
        options={{
          readOnly: disabled || readOnly,
          minimap: {
            enabled: false,
          },
        }}
      />
    </div>
  );
};
