export const ENVIRONMENT = import.meta.env.ENVIRONMENT;
export const PROJECT_VERSION = import.meta.env.PROJECT_VERSION;
export const AUTH0_DOMAIN = import.meta.env.AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = import.meta.env.AUTH0_CLIENT_ID;
export const UM_API_BASE_URL = import.meta.env.UM_API_BASE_URL;
export const CSPM_API_BASE_URL = import.meta.env.CSPM_API_BASE_URL;
export const DATE_TIME_FORMAT = "DD MMM YYYY HH:mm:ss";

export const SENTRY_COMMON = {
  dsn: import.meta.env.SENTRY_DSN,
  release: import.meta.env.PROJECT_VERSION,
  tracesSampleRate: 1.0
};

export const getSentryConfig = () => {
  return {
    ...SENTRY_COMMON,
    // Auto-inject current environment
    environment: ENVIRONMENT,
    // Disable tracing for local domains unless enforced by the developer
    denyUrls: import.meta.env.TRACING_FORCE
      ? []
      : ["0.0.0.0", "localhost", "127.0.0.1", "::1"],
    debug: import.meta.env.TRACING_DEBUG === "yes",
  };
};
