import React, { useMemo } from "react";
import {
  useDataGrid,
  ShowButton,
  DeleteButton,
  List,
  DateField,
} from "@refinedev/mui";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  IResourceComponentsProps,
  useOne,
  useResource,
  useTranslate,
} from "@refinedev/core";
import { ButtonGroup } from "@mui/material";
import { useMatch } from "react-router-dom";
import { DATE_TIME_FORMAT } from "@/Environment";
import { CustomerProductsBreadcrumbs } from "@/components/breadcrumbs";
import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";

export const CustomerProductUserRoleList: React.FC<
  IResourceComponentsProps
> = () => {
  const translate = useTranslate();

  const { id, resource } = useResource();
  const match = useMatch(`${resource?.list}`);
  const customer_id = match?.params.customer_id ?? "";
  const product_id = match?.params.product_id ?? "";
  const meta = useMemo(
    () => ({
      customer_id,
      product_id,
    }),
    [customer_id, product_id]
  );

  const { data: customer, isLoading: customerIsLoading } = useOne({
    resource: "customers",
    id: customer_id,
  });

  const { data: product, isLoading: productIsLoading } = useOne({
    resource: "products",
    id: product_id,
  });

  const { dataGridProps } = useDataGrid({
    meta,
  });

  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: "actions",
        headerName: translate("table.actions"),
        sortable: false,
        renderCell: function render({ row }) {
          const actionsMeta = { ...meta, user_id: row.userId };
          return (
            <ButtonGroup
              className="ListActions"
              color="info"
              variant="outlined"
              size="small"
            >
              <ShowButton hideText recordItemId={row.id} meta={actionsMeta} />
              <DeleteButton hideText recordItemId={row.id} meta={actionsMeta} />
            </ButtonGroup>
          );
        },
        align: "center",
        headerAlign: "center",
        minWidth: 130,
        maxWidth: 170,
      },
      {
        field: "userId",
        headerName: translate("roles.fields.userId"),
        type: "number",
        minWidth: 80,
        maxWidth: 80,
      },
      {
        field: "id",
        headerName: translate("customer-product-user-roles.fields.userRoleId"),
        type: "number",
        minWidth: 110,
        maxWidth: 110,
      },
      {
        field: "roleName",
        flex: 1,
        headerName: translate("roles.fields.roleName"),
        minWidth: 220,
      },
      {
        field: "updatedOn",
        flex: 1,
        headerName: translate("roles.fields.updatedOn"),
        maxWidth: 170,
        renderCell: function render({ value }) {
          return (
            <DateField
              className="DateTimeFullCell"
              value={value}
              format={DATE_TIME_FORMAT}
            />
          );
        },
      },
    ],
    [translate, id, meta]
  );

  const isPending =
    dataGridProps.loading || customerIsLoading || productIsLoading;

  return (
    <List
      createButtonProps={{ meta }}
      breadcrumb={
        <CustomerProductsBreadcrumbs
          customer={customer?.data}
          product={product?.data}
        />
      }
    >
      {isPending ? (
        <ContentLoadingIndicator fill />
      ) : (
        <DataGrid
          {...dataGridProps}
          loading={isPending}
          columns={columns}
          autoHeight
          forwardedProps={{ meta }}
        />
      )}
    </List>
  );
};
