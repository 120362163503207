import { Create } from "@refinedev/mui";
import { Box, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useOne, useResource, useTranslate } from "@refinedev/core";
import { useMatch } from "react-router-dom";
import { useMemo } from "react";
import { CustomerProductsBreadcrumbs } from "@/components/breadcrumbs";

export const CustomerProductPermissionTemplateCreate: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();

  const { resource } = useResource("customer-product-permission-templates");
  const match = useMatch(`${resource?.create}`);
  const customer_id = match?.params.customer_id ?? "";
  const product_id = match?.params.product_id ?? "";
  const meta = useMemo(
    () => ({
      customer_id,
      product_id,
    }),
    [customer_id, product_id]
  );

  const { data: customer, isLoading: customerIsLoading } = useOne({
    resource: "customers",
    id: customer_id,
  });

  const { data: product, isLoading: productIsLoading } = useOne({
    resource: "products",
    id: product_id,
  });

  const {
    saveButtonProps,
    refineCore: { formLoading },
    register,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      resource: "customer-product-permission-templates",
      meta,
    },
  });

  const isPending = formLoading || customerIsLoading || productIsLoading;

  return (
    <Create
      breadcrumb={
        <CustomerProductsBreadcrumbs
          customer={customer?.data}
          product={product?.data}
          lastPaths={[
            {
              id: "customer-product-permission-templates",
              path: `/customers/${customer?.data.id}/products/${product?.data.id}/permission-templates`,
              title: translate("permissions.permissions", "Permission templates"),
            },
          ]}
        />
      }
      isLoading={isPending}
      saveButtonProps={saveButtonProps}
    >
      <Box component="form" sx={{ display: "flex", flexDirection: "column" }} autoComplete="off">
        <TextField
          {...register("expressionTemplate", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.expressionTemplate}
          helperText={(errors as any)?.expressionTemplate?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("customer-product-permission-templates.fields.expressionTemplate")}
          name="expressionTemplate"
        />
        <TextField
          {...register("expectedKeys", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.expectedKeys}
          helperText={(errors as any)?.expectedKeys?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("customer-product-permission-templates.fields.expectedKeys")}
          name="expectedKeys"
        />
        <TextField
          {...register("description", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.description}
          helperText={(errors as any)?.description?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("customer-product-permission-templates.fields.description")}
          name="description"
        />
      </Box>
    </Create>
  );
};
