import { Create } from "@refinedev/mui";
import { Box } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useResource } from "@refinedev/core";
import { useMemo } from "react";
import { Controller } from "react-hook-form";
import { AdvancedEditor } from "@/components/editors/AdvancedEditor";

export const ProductPermissionModelCreate: React.FC<IResourceComponentsProps> = () => {
  const { id } = useResource();
  const meta = useMemo(
    () => ({
      product_id: id,
    }),
    [id]
  );
  const {
    saveButtonProps,
    refineCore: { formLoading },
    control,
  } = useForm({
    refineCoreProps: {
      resource: "product-permission-model",
      meta,
    },
  });

  return (
    <Create isLoading={formLoading} saveButtonProps={saveButtonProps}>
      <Box component="form" sx={{ display: "flex", flexDirection: "column" }} autoComplete="off">
        <Controller
          control={control}
          name="permissionModel"
          rules={{ required: "This field is required" }}
          defaultValue={null}
          render={(props) => {
            return (
              <AdvancedEditor
                readOnly={formLoading}
                onChange={(value: string) => {
                  props.field.onChange(value);
                }}
              />
            );
          }}
        />
      </Box>
    </Create>
  );
};
