import { useShow, IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Show, NumberField, DateField, TextFieldComponent as TextField } from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";

// project

import { DATE_TIME_FORMAT } from "@/Environment";
import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";

export const RoleShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow({
    queryOptions: {
      refetchOnMount: true,
      cacheTime: 0,
      staleTime: 0,
      retry: false,
    },
  });
  const { data, isLoading } = queryResult;
  const record = data?.data;
  return (
    <Show isLoading={isLoading}>
      {isLoading ? (
        <ContentLoadingIndicator fill />
      ) : (
        <Stack gap={1}>
          <Typography variant="body1" fontWeight="bold">
            {translate("roles.fields.id")}
          </Typography>
          <NumberField value={record?.id ?? ""} />
          <Typography variant="body1" fontWeight="bold">
            {translate("roles.fields.name")}
          </Typography>
          <TextField value={record?.name} />
          <Typography variant="body1" fontWeight="bold">
            {translate("roles.fields.description")}
          </Typography>
          <TextField value={record?.description} />
          <Typography variant="body1" fontWeight="bold">
            {translate("roles.fields.contactName")}
          </Typography>
          <DateField value={record?.createdOn} format={DATE_TIME_FORMAT} />
          <Typography variant="body1" fontWeight="bold">
            {translate("roles.fields.updatedOn")}
          </Typography>
          <DateField value={record?.updatedOn} format={DATE_TIME_FORMAT} />
        </Stack>
      )}
    </Show>
  );
};
