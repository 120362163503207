import {
  useShow,
  IResourceComponentsProps,
  useTranslate,
  useOne,
  useResource,
} from "@refinedev/core";
import {
  Show,
  NumberField,
  DateField,
  TextFieldComponent as TextField,
  EditButton,
  DeleteButton,
  RefreshButton,
} from "@refinedev/mui";
import { Typography, Stack, Box } from "@mui/material";

import { DATE_TIME_FORMAT } from "@/Environment";
import { useMatch } from "react-router-dom";
import { useMemo } from "react";
import { CustomerBreadcrumbs } from "@/components/breadcrumbs";
import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";

export const CustomerProductShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();

  const { resource } = useResource("customer-products");
  const match = useMatch(`${resource?.show}`);
  const customer_id = match?.params.customer_id ?? "";
  const product_id = match?.params.product_id ?? "";
  const meta = useMemo(
    () => ({
      customer_id,
      product_id,
    }),
    [customer_id, product_id]
  );

  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  const { data: customer, isLoading: customerIsLoading } = useOne({
    resource: "customers",
    id: customer_id,
  });

  const isPending = isLoading || customerIsLoading;

  return (
    <Show
      isLoading={isPending}
      breadcrumb={
        <CustomerBreadcrumbs
          customer={customer?.data}
          lastPaths={[
            {
              id: "products-list",
              path: `/customers/${customer_id}/products`,
              title: translate("products.products", "Products"),
            },
          ]}
        />
      }
      headerButtons={({
        refreshButtonProps,
        editButtonProps,
        deleteButtonProps,
      }) => (
        <Box display="flex" gap="16px">
          <EditButton {...editButtonProps} meta={meta} />
          <DeleteButton {...deleteButtonProps} meta={meta} />
          <RefreshButton {...refreshButtonProps} meta={meta} />
        </Box>
      )}
    >
      {isPending ? (
        <ContentLoadingIndicator fill />
      ) : (
        <Stack gap={1}>
          <Typography variant="body1" fontWeight="bold">
            {translate("products.fields.id")}
          </Typography>
          <NumberField value={record?.id ?? ""} />
          <Typography variant="body1" fontWeight="bold">
            {translate("products.fields.code")}
          </Typography>
          <TextField value={record?.code} />
          <Typography variant="body1" fontWeight="bold">
            {translate("products.fields.displayName")}
          </Typography>
          <TextField value={record?.displayName} />
          <Typography variant="body1" fontWeight="bold">
            {translate("products.fields.description")}
          </Typography>
          <TextField value={record?.description} />
          <Typography variant="body1" fontWeight="bold">
            {translate("products.fields.createdOn")}
          </Typography>
          <DateField value={record?.createdOn} format={DATE_TIME_FORMAT} />
          <Typography variant="body1" fontWeight="bold">
            {translate("products.fields.updatedOn")}
          </Typography>
          <DateField value={record?.updatedOn} format={DATE_TIME_FORMAT} />
        </Stack>
      )}
    </Show>
  );
};
