import { Edit } from "@refinedev/mui";
import { Box, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";

import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";

export const CustomerEdit: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const {
    saveButtonProps,
    refineCore: { queryResult },
    register,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      queryOptions: {
        refetchOnMount: true,
        cacheTime: 0,
        staleTime: 0,
        retry: false,
      },
    },
  });

  return (
    <Edit isLoading={queryResult?.isLoading} saveButtonProps={saveButtonProps}>
      {queryResult?.isLoading ? (
        <ContentLoadingIndicator fill />
      ) : (
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <TextField
            {...register("id", {
              required: "This field is required",
              valueAsNumber: true,
            })}
            error={!!(errors as any)?.id}
            helperText={(errors as any)?.id?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="number"
            label={translate("customers.fields.id")}
            name="id"
            disabled
          />

          <TextField
            {...register("code", {
              required: "This field is required",
            })}
            error={!!(errors as any)?.code}
            helperText={(errors as any)?.code?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label={translate("customers.fields.code")}
            name="code"
          />
          <TextField
            {...register("displayName", {
              required: "This field is required",
            })}
            error={!!(errors as any)?.displayName}
            helperText={(errors as any)?.displayName?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label={translate("customers.fields.displayName")}
            name="displayName"
          />
          <TextField
            {...register("description", {
              required: "This field is required",
            })}
            error={!!(errors as any)?.description}
            helperText={(errors as any)?.description?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label={translate("customers.fields.description")}
            name="description"
          />
          <TextField
            {...register("contactName", {
              required: "This field is required",
            })}
            error={!!(errors as any)?.contactName}
            helperText={(errors as any)?.contactName?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label={translate("customers.fields.contactName")}
            name="contactName"
          />
          <TextField
            {...register("contactEmail", {
              required: "This field is required",
            })}
            error={!!(errors as any)?.contactEmail}
            helperText={(errors as any)?.contactEmail?.message}
            margin="normal"
            fullWidth
            InputLabelProps={{ shrink: true }}
            type="text"
            label={translate("customers.fields.contactEmail")}
            name="contactEmail"
          />
        </Box>
      )}
    </Edit>
  );
};
