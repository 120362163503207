import { Breadcrumb, Edit, ListButton, RefreshButton } from "@refinedev/mui";
import { Box, TextField } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useResource, useTranslate } from "@refinedev/core";
import { useMatch } from "react-router-dom";
import { useMemo } from "react";

export const CustomerProductPermissionEdit: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();

  const { resource } = useResource();
  const match = useMatch(`${resource?.edit}`);
  const customer_id = match?.params.customer_id ?? "";
  const product_id = match?.params.product_id ?? "";
  const meta = useMemo(
    () => ({
      customer_id,
      product_id,
    }),
    [customer_id, product_id]
  );

  const {
    saveButtonProps,
    register,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      meta,
    },
  });

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      breadcrumb={<Breadcrumb meta={meta} />}
      headerButtons={({ refreshButtonProps, listButtonProps }) => (
        <Box display="flex" flexDirection="row" alignItems="center">
          <ListButton {...listButtonProps} meta={meta} />
          <RefreshButton {...refreshButtonProps} meta={meta} />
        </Box>
      )}
    >
      <Box component="form" sx={{ display: "flex", flexDirection: "column" }} autoComplete="off">
        <TextField
          {...register("id", {
            required: "This field is required",
            valueAsNumber: true,
          })}
          error={!!(errors as any)?.id}
          helperText={(errors as any)?.id?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="number"
          label={translate("customer-product-permissions.fields.id")}
          name="id"
          disabled
        />
        <TextField
          {...register("name", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.name}
          helperText={(errors as any)?.name?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("customer-product-permissions.fields.name")}
          name="name"
        />
        <TextField
          {...register("description", {
            required: false,
          })}
          error={!!(errors as any)?.description}
          helperText={(errors as any)?.description?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("customer-product-permissions.fields.description")}
          name="description"
        />
        <TextField
          {...register("expression", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.expression}
          helperText={(errors as any)?.expression?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("customer-product-permissions.fields.expression")}
          name="expression"
        />
        <TextField
          {...register("subject", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.subject}
          helperText={(errors as any)?.subject?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("customer-product-permissions.fields.subject")}
          name="subject"
        />
        <TextField
          {...register("subjectType", {
            required: "This field is required",
          })}
          error={!!(errors as any)?.subjectType}
          helperText={(errors as any)?.subjectType?.message}
          margin="normal"
          fullWidth
          InputLabelProps={{ shrink: true }}
          type="text"
          label={translate("customer-product-permissions.fields.subjectType")}
          name="subjectType"
        />
      </Box>
    </Edit>
  );
};
