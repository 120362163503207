import { AuthProvider } from "@refinedev/core";
import { useAuth0 } from "@auth0/auth0-react";

// project

import { axiosInstance, performTokenExchange } from "@/Api.dataProvider";

export function useAuthProvider() {
  const { isLoading, user, logout, getAccessTokenSilently } = useAuth0();
  console.debug("Using auth provider", { isLoading, user });
  const authProvider: AuthProvider = {
    login: async () => {
      console.debug("Auth provider login");
      return {
        success: true,
      };
    },
    logout: async () => {
      console.debug("Auth provider logout");
      sessionStorage.removeItem("session.token.value");
      logout({ logoutParams: { returnTo: window.location.origin } });
      return {
        success: true,
      };
    },
    onError: async (error) => {
      console.error("Auth provider error", error);
      return { error };
    },
    check: async () => {
      console.debug("Checking current auth session");
      try {
        const proceed = (token: string, persist: boolean) => {
          if (persist) {
            sessionStorage.setItem("session.token.value", token);
          }
          axiosInstance.defaults.headers.common = {
            Authorization: `Bearer ${token}`,
          };
          console.debug("Token injected inside axios instance", { token });
          return {
            authenticated: true,
          };
        };
        const storedToken = sessionStorage.getItem("session.token.value");
        if (storedToken) {
          console.debug("Found current auth session - proceed");
          return proceed(storedToken, false);
        }
        const token = await getAccessTokenSilently();
        if (token) {
          console.debug("Fetching application access token");
          const sessionToken = await performTokenExchange(token);
          return proceed(sessionToken, true);
        } else {
          console.error("Unable to get Auth0 SSO access token");
          return {
            authenticated: false,
            error: {
              message: "Check failed",
              name: "Token not found",
            },
            redirectTo: "/login",
            logout: true,
          };
        }
      } catch (error: unknown) {
        return {
          authenticated: false,
          error: new Error(`${error}`),
          redirectTo: "/login",
          logout: true,
        };
      }
    },
    getPermissions: async () => null,
    getIdentity: async () => {
      console.debug("Auth provider getIdentity", user);
      if (user) {
        return {
          ...user,
          avatar: user.picture,
        };
      }
      return null;
    },
  };
  return { isLoading, authProvider };
}
