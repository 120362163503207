import {
  useShow,
  IResourceComponentsProps,
  useTranslate,
} from "@refinedev/core";
import {
  Show,
  NumberField,
  DateField,
  TextFieldComponent as TextField,
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";
import { DATE_TIME_FORMAT } from "@/Environment";
import { ContentLoadingIndicator } from "@/components/information/ContentLoadingIndicator";

export const ProductShow: React.FC<IResourceComponentsProps> = () => {
  const translate = useTranslate();
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      {isLoading ? (
        <ContentLoadingIndicator fill />
      ) : (
        <Stack gap={1}>
          <Typography variant="body1" fontWeight="bold">
            {translate("products.fields.id")}
          </Typography>
          <NumberField value={record?.id ?? ""} />
          <Typography variant="body1" fontWeight="bold">
            {translate("products.fields.code")}
          </Typography>
          <TextField value={record?.code} />
          <Typography variant="body1" fontWeight="bold">
            {translate("products.fields.displayName")}
          </Typography>
          <TextField value={record?.displayName} />
          <Typography variant="body1" fontWeight="bold">
            {translate("products.fields.description")}
          </Typography>
          <TextField value={record?.description} />
          <Typography variant="body1" fontWeight="bold">
            {translate("products.fields.createdOn")}
          </Typography>
          <DateField value={record?.createdOn} format={DATE_TIME_FORMAT} />
          <Typography variant="body1" fontWeight="bold">
            {translate("products.fields.updatedOn")}
          </Typography>
          <DateField value={record?.updatedOn} format={DATE_TIME_FORMAT} />
        </Stack>
      )}
    </Show>
  );
};
